import Cookies from "js-cookie";
import DelayedAction from "../common/DelayedAction";

export default class AnaliticsUtils{
	constructor(public_analytics_enabled=false, analytics_account=null, ads_account=null){
		this.public_analytics_enabled = public_analytics_enabled;

		if(this.public_analytics_enabled){
			window.dataLayer = window.dataLayer || [];
		  window.gtag = function gtag(){dataLayer.push(arguments);}
		  gtag('js', new Date());
		  if(analytics_account){
		  	gtag('config', analytics_account);
		  	// gtag('config', 'UA-86892970-2');
		  }
		  if(ads_account){
		  	gtag('config', ads_account);
		  	// gtag('config', 'AW-824334856');
			}
		}
	}

	sendEvent(status, attrs= {}){
		// console.log(status);
		// console.log(attrs);
		if(this.public_analytics_enabled){
			switch(status){
				case "GtagViewItem":
				{
					this.startGtag('event', 'view_item', attrs)
					break;
				}
				case "GtagBeginCheckout":
				{
					this.startGtag('event', 'begin_checkout', attrs);
					break;
				}
				case "GtagCheckoutProgress":
				{
    			this.startGtag('event', 'checkout_progress', attrs);
					break;
				}
				case "FbTrack":
				{
					this.initFbTrack(attrs);
					break;
				}
				case "FbCheckout":
				{
					this.startFbq('track', 'InitiateCheckout');
					break;
				}
				case "FbPurchase":
				{
					this.initFbPurchase(attrs);
				  break;
				}
				case "GtagPurchase":
				{
					this.initGtagPurchase(attrs);
					break;
				}
				case "GtagConversion":
				{
					this.initGtagConversion(attrs);
					break;
				}
				case "GaTrackerFailed":
				case "GaTrackerComplete":
				case "GaTrackerView":
				case "GaTrackerStep1":
				{
					this.initGaTracker(status, attrs);
				}
			}
		}
	}

	initFbPurchase(attrs){
		if(attrs.value && attrs.currency && attrs.id){
			let prev = Cookies.get("Analitics_FbPurchase");
			if(prev && prev==(attrs.id+"")){
				// console.log("Already sent Analitics_FbPurchase")
			}else{

				this.startFbq('track', 'Purchase', {
			    value: attrs.value,
			    currency: attrs.currency,
			    content: [
			    	{
			    		id: attrs.id,
			    		quantity: 1
			    	}
			    ]
			  });
			 	Cookies.set("Analitics_FbPurchase", attrs.id, { sameSite: 'lax' });
			}
		}
	}

	initGtagPurchase(attrs){
		if(attrs.id && attrs.currency && attrs.experience_id){
			let prev = Cookies.get("Analitics_GtagPurchase");
			if(prev && prev==(attrs.id+"")){
				// console.log("Already sent Analitics_GtagPurchase")
			}else{
				this.startGtag('event', 'purchase', {
				  "transaction_id": attrs.booking_uid,
				  "affiliation": attrs.affiliate_name,
				  "value": attrs.price,
				  "currency": attrs.currency,
				  "items": [
				    {
				      "id": attrs.experience_id,
				      "name": attrs.experience_name,
				      "brand": "Globol",
				      "category": attrs.city_name,
				      "list_name": attrs.city_name,
				      "variant": attrs.option_name,
				      "price": attrs.price,
				      "quantity": 1
				    }
				  ]
				});

				Cookies.set("Analitics_GtagPurchase", attrs.id, { sameSite: 'lax' });
			}
		}
	}

	initGtagConversion(attrs){
		if(attrs.price && attrs.currency && attrs.id){
			let prev = Cookies.get("Analitics_GtagConversion");
			if(prev && prev==(attrs.id+"")){
				// console.log("Already sent Analitics_GtagConversion")
			}else{
				this.startGtag('event', 'conversion', {
			    'send_to': 'AW-824334856/AcycCJOt9q8BEIi0iYkD',
			    'value': attrs.price,
			    'currency': attrs.currency,
			    'transaction_id': attrs.booking_uid
				});

				Cookies.set("Analitics_GtagConversion", attrs.id, { sameSite: 'lax' });
			}
		}
	}

	initFbTrack(attrs){
		if(attrs.price && attrs.currency){
			let prev = Cookies.get("Analitics_FbTrack");
			if(prev && prev==(attrs.id+"")){
				// console.log("Already sent Analitics_FbTrack")
			}else{
				console.log("Start FbTrack")
				this.startFbq('ViewContent', {
					content_ids: [attrs.experience_id],
					content_type: 'destination',
					content_name: attrs.experience_seo_title,
					value: attrs.price,
					currency: attrs.currency
				});
				Cookies.set("Analitics_FbTrack", attrs.experience_id, { sameSite: 'lax' });
			}
		}
	}

	startFbq(...params){
		this.startAnalitics('fbq', params);
	}

	startGtag(...params){
		this.startAnalitics('gtag', params);
	}

	initGaTracker(status, params){
		// console.log("init ga tracker: ",params.ga_params)
		if(params.ga_params.id){
			let prev = Cookies.get("Analitics_GaTracker_"+status);
			if(prev && prev==(params.ga_params.id+"")){
				// console.log("Already sent Analitics_GaTracker")
			}else{
				new DelayedAction(
					function() {
						return (
							typeof ga !== 'undefined' &&
							typeof ga.getAll === 'function' &&
							ga.getAll()[0]
						);
					},
					function() {
						// console.log("start ga tracker")
						let tracker = ga.getAll()[0];
						tracker.send(params.ga_params);
						Cookies.set("Analitics_GaTracker_"+status, params.ga_params.id, { sameSite: 'lax' });
					}
				);
			}
		}

	}


	startAnalitics(func_name, params){
		new DelayedAction(
			function() {
				// console.log("Try function: ",func_name)
				return typeof window[func_name] !== 'undefined';
			},
			function() {
				// console.log("Start function: ",func_name)
				window[func_name](...params);
			}
		);

	}


}
