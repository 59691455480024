import "corejs-typeahead/dist/typeahead.jquery.js"
import Bloodhound from "corejs-typeahead/dist/bloodhound.js"

export default class InitTypeahead{
  constructor(track_analytics=false) {
    this.track_analytics=track_analytics;
    this.selector = ".typeahead";
    $(this.selector).each((index, value) => {
      let $value = $(value)
      let path= $value.data('path');
      var server_data = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: path,
        remote: {
          url: path+'?term=%QUERY',
          wildcard: '%QUERY',
          transform: function(r) {
            return r;
          }
        },
      });
      $value.typeahead(
      {
        hint: true,
        highlight: false,
        minLength: 3
      },
      {
        name: 'server_datas',
        autoSelect: false,
        display: 'value',
        source: server_data,
        limit: Infinity,
        templates: {
          suggestion: function(context) {
            return $(`<div class="typeahead_${context.type}">`).html(context.html);
          }
        },
      });

      $value.removeAttr("aria-activedescendant");
      $value.removeAttr("aria-autocomplete");
      $value.removeAttr("aria-owns");
      $value.removeAttr("aria-readonly");
      $value.removeAttr("role");



      this.toggleDrywall($value, false);
    })

    this.addListeners();

  }

  addListeners() {
    $(document).on('typeahead:select', ".typeahead", (ev, suggestion) => {
      console.log(ev, suggestion);
      location.href = suggestion.url
    });
    $(document).on('click',".typeahead_clear_button",(event)=>{
      const $target = $($(event.currentTarget).data("typeaheadSelector"));
      $(event.currentTarget).hide();
      $target.each((index,value)=>{
        if($target.length>0){
          let $typeahead = $(value)
          $typeahead.typeahead('val', '');
          $typeahead.focus();
          this.toggleDrywall($typeahead, false);
        }
      });
    });
    $(document).on('keyup', this.selector, event => {
      const $typeahead = $(event.currentTarget);
      let $parent = $typeahead.closest(".search-container");
      if($parent.length==0){
        $parent = $typeahead.closest(".search-shop-container");
      }
      const $clear_button = $parent.find(".typeahead_clear_button");


      // const $clear_button = $typeahead.closest(".search-container").find(".typeahead_clear_button");
      if($typeahead.typeahead('val').length>0){
        $clear_button.show();
        if($typeahead.typeahead('val').length<3){
          this.toggleDrywall($typeahead, false);
        }else{
          this.toggleDrywall($typeahead, true);
        }
      }else{
        $clear_button.hide();
      }

    });

    $(document).on('typeahead:beforeclose', this.selector, event => {
      event.preventDefault();
    });


    $(document).on('typeahead:open', this.selector, event => {
      const $current = $(event.currentTarget);
      if(!$current.siblings('.tt-menu').hasClass("tt-empty")){
        if($current.val()){
          this.toggleDrywall($current, true);
        }else{
          this.toggleDrywall($current, false);
        }
      }else{
        this.toggleDrywall($current, false);
      }
    });


    $(document).on('typeahead:close', this.selector, event => {
      const $current = $(event.currentTarget);
      this.toggleDrywall($current, false);
    });

    $(document).on('typeahead:asyncreceive', this.selector, (event,suggestion,query) => {

      if(this.track_analytics){
        ga.getAll()[0].send({"hitType": "pageview", "page": '/public_search?q='+query, "title": "search"});
      }
    });




  }

  toggleDrywall($input, visibility) {
    if (visibility) {
      $input.parent().siblings(".drywall").show();
    } else {
      $input.parent().siblings(".drywall").hide();
    }
  }
}
