import 'bootstrap';

import 'fg-loadcss/dist/cssrelpreload';


import LazyLoad from "vanilla-lazyload";

window.InitTypeahead = require('../javascripts/common/InitTypeahead').default;
window.InitTooltip = require('../javascripts/backend/common/InitTooltip').default;

window.AnaliticsUtils = require('../javascripts/frontend/AnaliticsUtils').default;


window.Utils = require('../javascripts/common/Utils').default;
window.ExportDefaultEvents = require('../javascripts/common/ExportDefaultEvents').default;
new ExportDefaultEvents();

$(()=> {
	new Utils();
	new InitTooltip();
	if (typeof initLayout == 'function') {
		initLayout();
	}

	if (typeof initPage == 'function') {
		initPage();
	}
	new LazyLoad();

	// for all function in render partial with js
	window.late_functions.forEach(f=>f());
});

import 'stylesheets/frontend_home.scss'
